import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';

const ExiliumGuidesIncome: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page gfl-guide'} game="exilium">
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/gfl-exilium/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Monthly Pull Income</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/exilium/categories/category_income.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Monthly Pull Income</h1>
          <h2>
            How much currency and pulls you can obtain every month in GFL 2.
          </h2>
          <p>
            Last updated: <strong>28/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Monthly Pull Income" />
        <p>Coming soon!</p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default ExiliumGuidesIncome;

export const Head: React.FC = () => (
  <Seo
    title="Monthly Pull Income | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="How much currency and pulls you can obtain every month in GFL 2."
    game="exilium"
  />
);
